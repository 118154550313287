<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ pack.name }} - {{ $t("COMMON.GALLERY") }}
      </span>
    </div>

    <div class="row" style="">
      <div class="col-4" v-for="(image, key) in pack.gallery" :key="key">
        <img :src="image" class="argon-image mb-2" style="width: 100%" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "package-view-gallery",

  components: {},

  props: ["pack"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    pack(pack) {},
  },
};
</script>
