<template>
  <div class="container-fluid">
    <div class="row mb-4">
      <span class="display-4 col-12">{{ pack.name }}</span>
    </div>

    <div class="row">
      <div class="col">
        <dl class="row">
          <dt class="col-sm-12 mb-4 display-4">
            <router-link
              :to="{ name: 'View Role', params: { id: pack.default_role.id } }"
            >
              {{ pack.default_role.name }}
            </router-link>
          </dt>
          <role-permissions :role="pack.default_role" />
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
import RolePermissions from "@/components/RolePermissions.vue";

export default {
  name: "package-view-authorizations",

  components: { RolePermissions },

  props: ["pack"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
